import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { folderColors, FolderModel } from '@shared/models/survey.model';
import { FoldersApi } from '@shared/services/folders-api.service';
import { from } from 'rxjs';
import { StreamAction } from '@shared/decorators/stream-action.decorator';
import { map, tap } from 'rxjs/operators';
import { isEmpty } from '@shared/utilities/object.utilities';
import { Params } from '@angular/router';
import { RightsManager } from '@shared/services/rights-manager.service';
import { Rights } from '@shared/enums/rights.enum';
import { uniqueValues } from '@shared/utilities/array.utilities';
import { SignOutWithRedirect } from '@shared/states/auth.actions';
import { SwitchTeam } from '@shared/states/account.actions';
import { SurveyState } from '@shared/states/survey.state';
import { RouterState } from './router.state';
import { PrefsState } from './prefs.state';
import { AccountState } from './account.state';
import { GetIdentities } from './prefs.actions';
import {
  CreateFolder,
  DeleteFolder,
  DeselectSurveys,
  GetFolders,
  MoveToFolder,
  SelectSurveys,
  ToggleSurveyHover,
  ToggleSurveySelect,
  UpdateFolder,
} from './folders.actions';

export interface FoldersStateModel {
  data: { [folderKey: string]: FolderModel };
  selected: string[];
  hovered?: string;
}

const defaultStateModel = {
  data: {},
  selected: [],
};

@Injectable()
@State<FoldersStateModel>({
  name: 'folders',
  defaults: defaultStateModel,
})
export class FoldersState {
  @Selector()
  static selected(state: FoldersStateModel) {
    return state.selected;
  }

  @Selector()
  static folders(state: FoldersStateModel) {
    return Object.values(state.data);
  }

  static folder(folderKey: string) {
    return createSelector([FoldersState], ({ data }: FoldersStateModel): FolderModel => data[folderKey]);
  }

  @Selector()
  static hasSelected(state: FoldersStateModel) {
    return state.selected.length > 0;
  }

  @Selector()
  static isCustomColorInUse({ data }: FoldersStateModel) {
    return !Object.values(data).every((f) => folderColors.includes(f.color));
  }

  static isHovered(surveyKey: string) {
    return createSelector([FoldersState], ({ hovered }: FoldersStateModel): boolean => hovered === surveyKey);
  }

  static isSelected(surveyKey: string) {
    return createSelector([FoldersState], ({ selected }: FoldersStateModel): boolean => selected.includes(surveyKey));
  }

  static canViewFolder(folder: FolderModel) {
    return createSelector(
      [AccountState.isTeamAdmin, AccountState.teamKey, AccountState.user],
      (isTeamAdmin, teamKey, userData): boolean => {
        const userSurveys = (userData?.surveys || {})[teamKey] || {};
        const surveyKeys = Object.keys(folder.surveys || {});

        const hasSurveys = surveyKeys.length > 0;

        const userHasSurveyRights = surveyKeys.some((surveyKey) => userSurveys[surveyKey] > Rights.NONE);

        const userIsFolderOwner = folder?.createdBy === userData?.$key;

        return !!folder && (hasSurveys ? userHasSurveyRights || userIsFolderOwner || isTeamAdmin : userIsFolderOwner);
      },
    );
  }

  static surveyFolder(surveyKey: string) {
    return createSelector(
      [FoldersState],
      ({ data }: FoldersStateModel): FolderModel =>
        Object.values(data).find((f) => !!f.surveys && f.surveys[surveyKey]),
    );
  }

  @Selector([RouterState.routeParams])
  static selectedFolder({ data }: FoldersStateModel, { folderKey }: Params) {
    return data[folderKey];
  }

  constructor(
    private fa: FoldersApi,
    private rm: RightsManager,
    private store: Store,
  ) {}

  @StreamAction(GetFolders)
  GetFolders({ dispatch, patchState }: StateContext<FoldersStateModel>) {
    return this.fa.getFolders().pipe(
      map((folders) =>
        (folders || []).reduce(
          (dict, folder) => ({
            ...dict,
            [folder.$key]: { ...folder, $surveyCount: Object.keys(folder.surveys || {})?.length || 0 },
          }),
          {},
        ),
      ),
      tap((data) => {
        const teamKey = this.store.selectSnapshot(AccountState.teamKey);
        const userKeys = Object.values(data)
          .map((f: FolderModel) => f.createdBy)
          .filter(uniqueValues)
          .filter((key) => !this.store.selectSnapshot(PrefsState.folderOwner(key)));

        if (userKeys.length > 0) {
          dispatch(new GetIdentities(teamKey, userKeys));
        }
      }),
      map((data) => patchState({ data })),
    );
  }

  @Action(ToggleSurveyHover)
  toggleSurveyHover({ patchState }: StateContext<FoldersStateModel>, { surveyKey }: ToggleSurveyHover) {
    patchState({ hovered: surveyKey });
  }

  @Action(SelectSurveys)
  selectSurveys({ patchState }: StateContext<FoldersStateModel>, { selected }: SelectSurveys) {
    patchState({
      selected,
      hovered: undefined,
    });
  }

  @Action(DeselectSurveys)
  deselectSurveys({ patchState }: StateContext<FoldersStateModel>) {
    patchState({
      selected: [],
      hovered: undefined,
    });
  }

  @Action(ToggleSurveySelect)
  toggleSurveySelect({ getState, patchState }: StateContext<FoldersStateModel>, { surveyKey }: ToggleSurveySelect) {
    const state = getState();

    if (state.selected.includes(surveyKey)) {
      const selected = state.selected.filter((key) => key !== surveyKey);
      patchState({ selected });
    } else {
      const selected = state.selected.concat(surveyKey);
      patchState({ selected });
    }
  }

  @Action(CreateFolder)
  createFolder({ getState }: StateContext<FoldersStateModel>, { data }: CreateFolder) {
    const folders = getState().data;
    const surveys = Object.keys(data.surveys) || [];

    const sourceFolders = Object.values(folders).filter((folder) =>
      surveys.some((surveyKey) => !!folder?.surveys && !!folder?.surveys[surveyKey]),
    );

    const sourceUpdate = sourceFolders
      .map((folder) => folder.$key)
      .reduce(
        (dict1, sourceFolderKey) =>
          surveys.reduce(
            (dict2, surveyKey) => ({ ...dict2, [`${sourceFolderKey}/surveys/${surveyKey}`]: null }),
            dict1,
          ),
        {},
      );

    if (!isEmpty(sourceUpdate)) {
      this.fa.updateData(sourceUpdate);
    }

    return from(this.fa.createFolder(data));
  }

  @Action(DeleteFolder)
  deleteFolder(ctx: StateContext<FoldersStateModel>, { folderKey }: DeleteFolder) {
    return from(this.fa.deleteFolder(folderKey));
  }

  @Action(UpdateFolder)
  updateFolder(ctx: StateContext<FoldersStateModel>, { folderKey, data }: UpdateFolder) {
    return from(this.fa.updateFolder(folderKey, data));
  }

  @Action(MoveToFolder)
  moveToFolder({ getState }: StateContext<FoldersStateModel>, { folderKey, surveyKeys }: MoveToFolder) {
    const folders = getState().data;

    const sourceFolders = Object.values(folders)
      .filter((folder) => folder.$key !== folderKey)
      .filter((folder) => surveyKeys.some((surveyKey) => !!folder?.surveys && !!folder?.surveys[surveyKey]));

    const sourceUpdate = sourceFolders
      .map((folder) => folder.$key)
      .reduce(
        (dict1, sourceFolderKey) =>
          surveyKeys.reduce(
            (dict2, surveyKey) => ({ ...dict2, [`${sourceFolderKey}/surveys/${surveyKey}`]: null }),
            dict1,
          ),
        {},
      );

    const targetUpdate = folderKey
      ? surveyKeys.reduce((dict, surveyKey) => ({ ...dict, [`${folderKey}/surveys/${surveyKey}`]: 1 }), {})
      : null;

    const folderOwner = folders[folderKey]?.createdBy;

    let setRight = Promise.resolve();

    if (!this.store.selectSnapshot(SurveyState.hasSurveyRight(surveyKeys, Rights.EDIT, folderOwner))) {
      setRight = this.rm.setMultipleSurveyRights(surveyKeys, folderOwner, Rights.EDIT);
    }

    // const update = {
    //    remove survey from old folder
    //   '<sourceFolderKey>/surveys/<surveyKey>': null,

    //    add survey to new folder
    //   '<folderKey>/surveys/<surveyKey>': 1,
    // };
    const update = { ...sourceUpdate, ...targetUpdate };
    // console.warn(update);
    return from(Promise.all([this.fa.updateData(update), setRight]));
  }

  @Action([SignOutWithRedirect, SwitchTeam])
  resetState({ setState }: StateContext<FoldersStateModel>) {
    setState(defaultStateModel);
  }
}
