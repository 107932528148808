import { Injectable, NgZone } from '@angular/core';
import {
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Params } from '@angular/router';
import { environment } from '@env/environment';
import { DiscoverSurvey } from '@home/shared/dialogs/discover-survey/discover-survey.dialog';
import { Navigate } from '@ngxs/router-plugin';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { StreamAction } from '@shared/decorators/stream-action.decorator';
import { TeamAdminSettings, TeamData, TeamParentSettings, TeamType } from '@shared/models/account.model';
import { LanguageData2, LocalesData } from '@shared/models/locale.model';
import {
  DesignData,
  ReleaseData,
  SharingData,
  SurveyModel,
  TemplateCategory,
  TemplateSurveyModel,
} from '@shared/models/survey.model';
import { DialogControl } from '@shared/services/dialog-control.service';
import { BlueprintsManager } from '@shared/services/templates-manager.service';
import { PrefsState } from '@shared/states/prefs.state';
import { arrayUnique } from '@shared/utilities/array.utilities';
import { pickBy } from '@shared/utilities/object.utilities';
import { map } from 'rxjs/operators';
import { PrefsStateModel } from '@shared/states/prefs-state.models';
import { AccountState } from './account.state';
import { LocalesState } from './locales/locales.state';
import { RouterState } from './router.state';
import {
  CreateTemplate,
  DiscoverTemplate,
  FilterTemplates,
  LoadCategories,
  LoadTemplate,
  LoadTemplates,
  ResetTemplatesFilter,
  SetTemplate,
  ToggleTemplate,
  UpdateTemplate,
  UpdateTemplateData,
} from './templates.actions';
import { shareRef } from '@shared/operators/share-ref.operator';
import { insertItem, patch } from '@ngxs/store/operators';

export interface TemplatesStateModel {
  templates: TemplateSurveyModel[];
  teamTemplates: Record<string, TemplateSurveyModel[]>;
  categories: TemplateCategory[];
  activeTemplate?: TemplateSurveyModel;
  activeTemplateKey?: string;
  activeGroup?: string;

  filtering: {
    query?: string;
    category?: string;
    language?: string;
    complexity?: number;
    visible?: boolean;
  };
}

@Injectable()
@State<TemplatesStateModel>({
  name: 'templates',
  defaults: {
    templates: [],
    teamTemplates: {},
    categories: [],
    filtering: {
      visible: false,
      language: '',
    },
  },
})
export class TemplatesState {
  private dialogRef?: MatDialogRef<DiscoverSurvey>;

  @Selector([PrefsState])
  static promotedTemplates({ templates }: TemplatesStateModel, { language }: PrefsStateModel) {
    const discoverable = ['grfuwy', 'iwncpj', 'bomati'];

    return templates
      .filter(({ template }) => discoverable.includes(template.webkey))
      .filter(({ template }) => template.language === language);
  }

  @Selector([AccountState.team])
  static teamTemplates({ teamTemplates }: TemplatesStateModel, { $key }: TeamData) {
    return teamTemplates[$key] || [];
  }

  @Selector([AccountState.team])
  static latestTemplate({ teamTemplates }: TemplatesStateModel, { $key }: TeamData) {
    const templates = teamTemplates[$key] || [];

    return [...templates]
      .sort((a, b) => {
        const A = a.survey.modified || a.survey.created;
        const B = b.survey.modified || b.survey.created;
        return A - B;
      })
      .pop();
  }

  private static getLocaleTemplates(templates: SurveyModel[], categories: TemplateCategory[], language: string) {
    return templates.filter((data) =>
      categories
        .filter((category) => category.language?.startsWith(language || ''))
        .some((category) => data.template.category === category.$key),
    );
  }

  @Selector([AccountState.parentSettings, AccountState.adminSettings])
  static filteredCategories(
    { categories, filtering }: TemplatesStateModel,
    parentSettings: TeamParentSettings,
    adminSettings: TeamAdminSettings,
  ) {
    if (adminSettings.type === TeamType.Child && parentSettings.onlyParentTemplates) {
      return [];
    }

    return categories.filter((category) => category.language.startsWith(filtering.language || ''));
  }

  @Selector([PrefsState.language])
  static templateFilter({ filtering }: TemplatesStateModel, lang: string) {
    const language = filtering.language === undefined ? lang : filtering.language;
    return { ...filtering, language };
  }

  @Selector([RouterState.routeParams])
  static previewActive(state: TemplatesStateModel, params: Params) {
    return state.activeTemplate && !params.survey && !params.surveyKey;
  }

  @Selector()
  static templates({ templates }: TemplatesStateModel) {
    return templates;
  }

  static model(templateKey: string) {
    return createSelector([TemplatesState], ({ templates }): SurveyModel => {
      return templates?.find((template) => template.$key === templateKey);
    });
  }

  @Selector([RouterState.routeParams])
  static selectedTemplate({ templates, categories }: TemplatesStateModel, params: Params) {
    // Template name or category name from URL
    const name = params && params.templateName;

    const template = templates.find((t) => t.template.key === name);
    const category = categories.find((c) => c.$key === name);

    if (category) {
      return templates.filter((t) => t.template.category === category.$key).map((t) => t.template.key)[0];
    }
    if (template) {
      return name;
    }

    return params.template || params.templateKey;
  }

  @Selector()
  static zeffiTemplates({ templates }: TemplatesStateModel) {
    const filtered = templates.filter(({ template }) => template.teamKey === environment.templatesTeamKey);

    // console.warn(filtered.map((t) => t.template.key));
    return filtered;
  }

  @Selector([TemplatesState.filteredCategories])
  static createViewTemplates({ templates, filtering }: TemplatesStateModel, categories: TemplateCategory[]) {
    const { category, language, query } = filtering;

    const filtered = templates
      .filter(({ template }) => (!language ? true : template.language === language))
      .filter(({ template }) => {
        const inFilteredCategory = categories.some((c) => c.$key === template.category);
        const fromActiveTeam = template.teamKey !== environment.templatesTeamKey;
        return !category
          ? inFilteredCategory || fromActiveTeam
          : template.category === category || template.teamKey === category;
      })
      .filter(({ template }) => {
        const templateSearchableText = [template.name].join(' ').toLowerCase();
        const searchQuery = (query || '').toLowerCase();

        return templateSearchableText.includes(searchQuery);
      })
      .sort((a, b) => {
        const A = a?.template?.key || '';
        const B = b?.template?.key || '';
        return A && B ? A.localeCompare(B, undefined, { numeric: true }) : 0;
      });

    // console.warn(filtered.map((t) => t.template.key));
    return filtered;
  }

  @Selector([LocalesState.languages])
  static templateLanaugages({ templates, categories }: TemplatesStateModel, languages: LanguageData2[]) {
    const categoryLanguages = categories.map((c) => c.language);
    const templateLanguages = templates.map((t) => t.template.language);
    const languageData = arrayUnique([...categoryLanguages, ...templateLanguages])
      .filter(Boolean)
      .sort()
      .map((code) => languages.find((language) => language.code === code));

    return languageData;
  }

  @Selector([RouterState.routeParams])
  static discoverViewTemplates({ templates, categories, filtering }: TemplatesStateModel, params: Params) {
    const ts = TemplatesState.getLocaleTemplates(templates, categories, filtering.language);
    const tz = TemplatesState.filtered(ts, filtering.complexity);

    const filtered = ts
      .filter(({ template }) => {
        // filter by category
        const searchCategory = (filtering.category || '').toLowerCase();
        const categoryKeys = categories
          .filter((t) => (t.name || '').toLowerCase().includes(searchCategory) || t.$key === searchCategory)
          .map((t) => t.$key);

        const name = params && params.templateName;
        const category = categories.find((c) => c.$key === name);

        return category ? template.category === category.$key : categoryKeys.includes(template.category);
      })
      .filter(({ template }) => tz.includes(template.key))
      .filter(({ template }) => template.discoverable ?? !!template.webkey)
      .filter(({ template }) => {
        // filter by term
        const templateSearchableText = [template.author, template.name].join(' ').toLowerCase();
        const searchQuery = (filtering.query || '').toLowerCase();

        return templateSearchableText.includes(searchQuery);
      });

    // console.warn(filtered.map(({ template }) => template.name));
    return filtered;
  }

  private static filtered(data: SurveyModel[], complexity = 0) {
    const sorted = [...data].sort((a, b) => {
      const A = a.template.questionCount || 0;
      const B = b.template.questionCount || 0;

      return A < B ? -1 : A > B ? 1 : 0;
    });

    const count = sorted.length;
    const step = 100 / count;
    let inRange: SurveyModel[];

    if (complexity < step) {
      const end = count + 1 + Math.floor(complexity / step);
      inRange = sorted.slice(0, end);
    } else if (complexity > step) {
      const start = Math.floor(complexity / step) - 1;
      inRange = sorted.slice(start);
    } else {
      inRange = sorted;
    }

    return inRange.map((t) => t.template.key);
  }

  @Selector()
  static categories({ categories }: TemplatesStateModel) {
    return categories;
  }

  @Selector()
  static activeTemplate({ activeTemplate }: TemplatesStateModel) {
    return activeTemplate;
  }

  @Selector()
  static duplicateName({ activeTemplate }: TemplatesStateModel) {
    const placeholder = $localize`Untitled Template`;

    return $localize`Copy of ${activeTemplate?.template?.name || placeholder}`;
  }

  @Selector([LocalesState.locales])
  static activeTemplateForPreview(
    { activeTemplate, activeTemplateKey }: TemplatesStateModel,
    localesData: { [key: string]: LocalesData },
  ): Partial<SurveyModel> {
    const locales = activeTemplate?.locales ? { ...activeTemplate.locales } : null;
    if (locales?.config && localesData?.config) {
      const config = Object.entries(locales.config).reduce((dict, [key, value]) => {
        const defaults = localesData?.config?.[key];
        return { ...dict, [key]: { ...defaults, ...value } };
      }, {});

      locales.config = config;
    }

    return (
      activeTemplate && {
        survey: { ...activeTemplate.survey, $key: activeTemplateKey },
        questions: activeTemplate.questions,
        outcomes: activeTemplate.outcomes || [],
        design: activeTemplate.design || new DesignData(),
        sharing: activeTemplate.sharing || new SharingData(),
        release: activeTemplate.release || new ReleaseData(),
        scoring: activeTemplate.scoring || null,
        locales,
      }
    );
  }

  @Selector()
  static activeTemplateKey({ activeTemplateKey }: TemplatesStateModel) {
    return activeTemplateKey;
  }

  @Selector()
  static activeTemplateTeam({ activeTemplate }: TemplatesStateModel) {
    return activeTemplate?.template?.teamKey || null;
  }

  @Selector([TemplatesState.activeTemplate])
  static templateLanguage({ filtering }: TemplatesStateModel, template: SurveyModel) {
    return filtering?.language || template?.survey?.language;
  }

  constructor(
    private zone: NgZone,
    private store: Store,
    private dc: DialogControl,
    private bm: BlueprintsManager,
  ) {}

  @Action(SetTemplate)
  setTemplate(ctx: StateContext<TemplatesStateModel>, { templateKey, skipNavigate }: SetTemplate): void {
    const url = templateKey ? `/surveys/create/${templateKey}` : '/surveys/create';

    const state = ctx.getState();
    const templates = state.templates;
    const activeTemplate = templates.find((t) => t.template.templateKey === templateKey);
    const queryParams = this.store.selectSnapshot(RouterState.queryParams);

    ctx.patchState({ activeTemplateKey: templateKey, activeTemplate });

    if (!skipNavigate) {
      if (templateKey) {
        ctx.dispatch(new Navigate([url], queryParams, { preserveFragment: true }));
      } else if (templateKey === '') {
        ctx.dispatch(new Navigate([url]));
      }
    }
  }

  @Action(DiscoverTemplate)
  discoverTemplate(
    ctx: StateContext<TemplatesStateModel>,
    { templateKey, templates, startView, componentFactoryResolver, viewContainerRef }: DiscoverTemplate,
  ) {
    const desktopConfig: MatDialogConfig<any> = {
      panelClass: 'discover-dialog',
      height: '1100px',
      width: '1600px',
      maxHeight: 'calc(100vh - 80px)',
      maxWidth: 'calc(100vw - 80px)',
    };

    const mobileConfig: MatDialogConfig<any> = {
      panelClass: 'discover-dialog-mobile',
      height: '1100px',
      width: '1600px',
      maxHeight: '100vh',
      maxWidth: '100vw',
    };

    const config: MatDialogConfig = {
      ...(this.store.selectSnapshot(PrefsState.isMobile) ? mobileConfig : desktopConfig),
      componentFactoryResolver,
      viewContainerRef,
    };

    const activeTemplate = templates.find((t) => t.template.templateKey === templateKey);

    ctx.patchState({ activeTemplateKey: templateKey, activeTemplate });

    this.zone.run(() => {
      if (!this.dialogRef) {
        const inputs = { templateKey, startView, templates };

        this.dialogRef = this.dc.open(DiscoverSurvey, inputs, config);

        this.dialogRef.afterClosed().subscribe(() => {
          this.dialogRef = undefined;
        });
      } else {
        this.dialogRef.componentInstance.changeTemplate(templateKey);
      }
    });
  }

  @Action(FilterTemplates)
  filterTemplates({ getState, patchState }: StateContext<TemplatesStateModel>, { filter }: FilterTemplates) {
    const oldFilter = getState().filtering;
    const newFilter = pickBy(filter, (val) => val !== null);

    patchState({ filtering: { ...oldFilter, ...newFilter } });
  }

  @Action(ResetTemplatesFilter)
  resetTemplatesFilter({ patchState }: StateContext<TemplatesStateModel>) {
    patchState({ filtering: { visible: false } });
  }

  @Action(ToggleTemplate)
  toggleTemplate(_, { teamKey, templateKey, value }: ToggleTemplate) {
    return this.bm.toggleTemplate(teamKey, templateKey, value);
  }

  @Action(LoadCategories)
  loadCategories({ patchState }: StateContext<TemplatesStateModel>) {
    return this.bm.loadCategories().pipe(map((categories) => patchState({ categories })));
  }

  @StreamAction(LoadTemplates)
  loadTemplates({ getState, patchState, dispatch }: StateContext<TemplatesStateModel>, { teamKey }: LoadTemplates) {
    return this.bm.loadTemplates(teamKey).pipe(
      map((models) => {
        const teamTemplates = { ...getState().teamTemplates, [teamKey]: models };
        const templates = Object.values(teamTemplates).flat();
        const activeTemplateKey = getState().activeTemplateKey;

        patchState({ templates, teamTemplates });

        if (activeTemplateKey) {
          dispatch(new SetTemplate(activeTemplateKey, true));
        }
      }),
      shareRef(),
    );
  }

  @StreamAction(LoadTemplate)
  loadTemplate({ getState, setState }: StateContext<TemplatesStateModel>, { teamKey, templateKey }: LoadTemplate) {
    const template = getState().templates?.find((template) => template.$key === templateKey);

    if (template) {
      return null;
    }

    return this.bm.loadTemplate(teamKey, templateKey).pipe(
      map((model) => {
        if (model) {
          setState(patch({ teamTemplates: patch({ [teamKey]: insertItem(model) }), templates: insertItem(model) }));
        }
      }),
      shareRef(),
    );
  }

  @Action([CreateTemplate, UpdateTemplate])
  createTemplate(_, { options }: CreateTemplate) {
    return this.bm.createTemplate(options);
  }

  @Action(UpdateTemplateData)
  updateTemplateData(_, { teamKey, data }: UpdateTemplateData) {
    return this.bm.updateTemplateData(teamKey, data);
  }
}
