import { OrderData } from '@shared/models/order.model';
import {
  LogicItemStatement,
  QuestionData,
  SurveyModel,
  WhenLogicStatement,
  WithLogic,
} from '@shared/models/survey.model';
import { assertArray } from '@shared/utilities/array.utilities';
import { Status } from '@shared/models/status.model';

export function logicDataToArray<T extends WithLogic & OrderData>(data: T[]): T[] {
  return assertArray(data)
    .map((item) => {
      if (item.showWhen && !Array.isArray(item.showWhen)) {
        item.showWhen = Object.entries(item.showWhen as any as Record<string, WhenLogicStatement>)
          .filter(([, entry]) => !!entry.logic)
          .map(([key, entry]) => ({
            ...entry,
            $key: key,
            logic: entry.logic
              ? {
                  ...entry.logic,
                  dateFrom: entry.logic?.dateFrom ? new Date(entry.logic.dateFrom) : null,
                  dateUntil: entry.logic?.dateUntil ? new Date(entry.logic.dateUntil) : null,
                }
              : null,
          }));
      }

      return item;
    })
    .sort(OrderData.sortByOrder);
}

export function logicArrayToData<T extends Partial<WithLogic & OrderData>>(data: T | T[]): T | T[] {
  const items = assertArray(data).map((item) => {
    if (Array.isArray(item.showWhen)) {
      item.showWhen = item.showWhen.reduce(
        (a, b) => ({
          ...a,
          [b.$key]: b,
        }),
        {},
      ) as any;
    }

    return item;
  });

  return Array.isArray(data) ? items : items[0];
}

export function isItemArchived(item: { archived?: boolean }, questions: QuestionData[]): boolean {
  if (item?.archived) {
    return true;
  }

  return !!questions?.find((question) => question.$key === (item as QuestionData)?.group)?.archived;
}

export function hasItemArchivedDependencies(
  item: { archived?: boolean } & WithLogic,
  questions: QuestionData[],
): boolean {
  return Object.keys(item?.showIf || {}).some((key) => questions?.find((q) => q.$key === key && q.archived));
}

export function assignTemplateLogicKeys(data: any = []) {
  const showIf = {};
  if (data.showIf?.length > 0) {
    const logicList = data.showIf as LogicItemStatement<any>[];

    logicList.forEach((logic: any) => {
      showIf[logic.index] = logic;
    });
  }

  return data?.showIf ? { ...data, showIf } : data;
}

export function getSurveyStatus(survey: SurveyModel): Status {
  const isPublished = !!(survey?.survey?.published && survey?.release?.online);
  const lastPublished = survey?.survey?.published || 0;

  return isPublished ? Status.Online : lastPublished ? Status.Offline : Status.Warning;
}
