import { Data, Params } from '@angular/router';
import { EditorTab } from '@shared/models/editor.model';
import { AppState } from '@shared/models/state.model';

/**
 * RouterState is managed by @ngxs/router-plugin
 *
 * Reusable selectors are exported from namespace.
 * Usage:
 *
 *    @Select(RouterState.url) url$: Observable<string>;
 */
/* eslint-disable @typescript-eslint/no-namespace */
export namespace RouterState {
  export function url({ router }: Partial<AppState>): string {
    return router?.state?.url || '';
  }

  export function fragment({ router }: Partial<AppState>): string {
    return router?.state?.fragment || '';
  }

  export function routeParams({ router }: Partial<AppState>): Params {
    return router?.state?.params || {};
  }

  export function routeParam<T extends string = string>(param: string) {
    return (state: Partial<AppState>): T | undefined => routeParams(state)[param];
  }

  export function data({ router }: Partial<AppState>): Data {
    return router?.state?.data || {};
  }

  export function dataParam<T = any>(param: string) {
    return (state: AppState): T | undefined => data(state)[param] as T;
  }

  export function queryParams({ router }: Partial<AppState>): Params {
    return router?.state?.queryParams || {};
  }

  export function isHomeRoute(state: AppState): boolean {
    return url(state) === '/home';
  }

  export function isCartRoute(state: AppState): boolean {
    return url(state).startsWith('/plans/cart');
  }

  export function editorTab(state: Partial<AppState>): EditorTab | undefined {
    const path = url(state);
    if (path.includes('surveys/')) {
      if (path.includes(`/${EditorTab.Edit}`)) {
        return EditorTab.Edit;
      }

      if (path.includes(`/${EditorTab.Design}`)) {
        return EditorTab.Design;
      }

      if (path.includes(`/${EditorTab.Setup}`)) {
        return EditorTab.Setup;
      }
    }
  }
}
