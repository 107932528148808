import { NgZone } from '@angular/core';
import { from, Observable } from 'rxjs';
import { UrlMatcher, UrlSegment } from '@angular/router';

/**
 * Returns an `Observable<T>` from `Promise<T>` that runs outside Angular zone
 *
 * @usageNotes
 *  ### Example
 *
 *  ```
 *  public getAuthToken() {
 *    const toPromise = () =>
 *      this.currentUser.getIdToken();
 *
 *    return runOutsideZone(this.zone)(toPromise);
 *  }
 * ```
 */
export function runOutsideZone(zone: NgZone) {
  return <T>(toPromise: (...args: any[]) => Promise<T>) =>
    new Observable<T>((subscriber) =>
      zone.runOutsideAngular(() =>
        from(toPromise()).subscribe(
          (value) =>
            zone.run(() => {
              subscriber.next(value);
              subscriber.complete();
            }),

          (error) =>
            zone.run(() => {
              subscriber.error(error);
              subscriber.complete();
            }),
        ),
      ),
    );
}

export function optionalRoute(path: string): UrlMatcher {
  const parts = path.split('/');

  return (urlSegments: UrlSegment[]) => {
    const posParams: { [name: string]: UrlSegment } = {};
    for (let i = 0; i < parts.length; i++) {
      if (parts[i].substr(0, 1) == ':' && parts[i].substr(-1, 1) == '?') {
        if (urlSegments[i]) {
          posParams[parts[i].substr(1, parts[i].length - 2)] = urlSegments[i];
        }
      } else if (parts[i].substr(0, 1) == ':') {
        if (!urlSegments[i]) {
          return null;
        }
        posParams[parts[i].substr(1, parts[i].length - 1)] = urlSegments[i];
      } else {
        if (!urlSegments[i] || urlSegments[i].path != parts[i]) {
          return null;
        }
      }
    }
    return {
      consumed: urlSegments,
      posParams: posParams,
    };
  };
}
