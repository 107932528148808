export interface EditorPrefs {
  hideArchiveWarningDialog?: boolean;
  hideAdminTools?: boolean;
  hideAiInterviewBanner?: boolean;
}

export enum EditorTab {
  Edit = 'edit',
  Design = 'design',
  Setup = 'settings',
}
